<template>
  <section class="keyword-wrapper" v-loading="loading">
    <el-form class="keyWordForm" :model="form" label-width="80px" :rules="rules" ref="form">
<!--      <el-form-item label="主题名称" prop="subjectName">-->
<!--        <el-input v-model="form.subjectName"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="规则配置">
        <div>
          <el-radio-group v-model="form.ruleType" @change="changeType">
            <el-radio-button :label="0" :value="0">普通</el-radio-button>
            <el-radio-button :label="1" :value="1">高级</el-radio-button>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="分类名称" prop="typeName">
        <el-input v-model="form.typeName" placeholder="请输入分类名称"></el-input>
      </el-form-item>
      <el-form-item label="核心词" class="el-textarea" prop="ruleContent" v-if="form.ruleType === 0">
        <el-input type="textarea" :rows="6" v-model="form.ruleContent"></el-input>
        <span class="rule">核心词用于分类依据，文章包含以上任一核心词，则系统判别为属于该分类。词与词之间用、号连接。</span>
        <span class="rule">英文字母区分大小写</span>
      </el-form-item>
            <el-form-item label="同现词" class="el-textarea" v-if="form.ruleType === 0">
              <textarea v-model="form.homonymWords"></textarea>
              <span class="rule">同现词，必须与核心词同时出现的词，词与词之间用、号连接。</span>
              <span class="rule">英文字母不区分大小写</span>
            </el-form-item>
      <el-form-item label="排除词" class="el-textarea" v-if="form.ruleType === 0">
        <textarea v-model="form.exclusionWords"></textarea>
        <span class="rule">排除词用于分类依据，文章包含以上任一排除词，则系统判别为不属于该分类。词与词之间用、号连接。</span>
        <span class="rule">英文字母区分大小写</span>
      </el-form-item>
      <el-form-item label="命中规则" class="words" prop="matchRule" v-if="form.ruleType === 1">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入命中规则"
          v-model="form.matchRule">
        </el-input>
        <p class="rules">通过英文括号、竖线、加号进行组合 (&nbsp;&nbsp;)&nbsp;&nbsp;|&nbsp;&nbsp;+</p>
        <p class="rules">示例1：(苹果+(iPhone|手机))|(Apple+(iPhone|手机))|(苹果+(iPad|平板))|(Apple+(iPad|平板))|(苹果+(MacBook|笔记本))|(Apple+(MacBook|笔记本))</p>
        <p class="rules">示例2：((苹果|Apple)+(iPhone|手机))|((苹果|Apple)+(iPad|平板))|((苹果|Apple)+(MacBook|笔记本))</p>
        <el-button size="small" style="float: right; margin-top: 20px" type="primary" @click="testMyRule(form.matchRule)">测试命中规则</el-button>
      </el-form-item>

      <el-form-item label="排除规则" class="words" v-if="form.ruleType === 1">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入排除规则"
          v-model="form.exclusionRule">
        </el-input>
        <p class="rules">通过英文括号、竖线、加号进行组合 (&nbsp;&nbsp;)&nbsp;&nbsp;|&nbsp;&nbsp;+</p>
        <el-button size="small" style="float: right; margin-top: 20px" type="primary" @click="testMyRule(form.exclusionRule)">测试排除规则</el-button>
      </el-form-item>
      <el-form-item size="large" style="padding-bottom: 40px">
        <div style="text-align: center">
          <el-button size="small" @click="goBack">返回</el-button>
          <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="规则测试"
      :visible.sync="dialogVisible"
      width="30%">
      <span>{{ ruleText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {classSave, selMenu, testRule, Updateclass} from '@/api/settings/MonitorSubject'
import eventHub from '@/utils/eventHub'
import { getNav } from '@/api/layout'
import {listToTree} from '@/utils/index.js'
export default {
  mounted () {
    // 这里获取到监测主题的id&所属上级的名称
    this.form.subjectName = this.$route.params.typeName
    this.type = this.$route.meta.type
    this.cid = this.$route.params.cid
    if (this.type === 'edit') {
      eventHub.$emit('msg', '修改分类')
    } else if (this.type === 'add') {
      this.loading = false
      eventHub.$emit('msg', '添加分类')
    }
    if (this.type === 'edit') {
      if (this.$route.params.cid === 'undefined') {
        this.viewselMenu(this.$route.params.id, this.$route.params.pid, null)
      } else {
        this.viewselMenu(this.$route.params.id, this.$route.params.pid, this.$route.params.cid)
      }
    }
  },
  data () {
    return ({
      form: {
        id: '',
        ruleContent: '',
        subjectName: '',
        typeName: '',
        industry: '',
        coreWords: '',
        homonymWords: '',
        exclusionWords: '',
        parentId: '',
        subjectId: '',
        cid: '',
        ruleType: 0,
        matchRule: '',
        exclusionRule: ''
      },
      loading: true,
      rules: {
        typeName: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
        ruleContent: [{ required: true, message: '请输入核心词', trigger: 'blur' }],
        matchRule: [{ required: true, message: '请输入命中规则', trigger: 'blur' }]
      },
      type: '',
      dialogVisible: false,
      ruleText: ''
    })
  },
  methods: {
    async Updateclass () {
      try {
        if (this.$route.params.cid === 'undefined') {
          this.form.cid = null
        } else {
          this.form.cid = this.$route.params.cid
        }
        this.form.parentId = this.$route.params.pid
        const res = await Updateclass(this.form)
        console.log(res)
        console.log(this.$route.params.cid)
        if (this.$route.params.cid === 'undefined') {
          this.$router.push('/settings/theme/')
        } else {
          this.$router.push('/settings/theme/show/' + this.form.cid)
        }
        return res
      } catch (err) {
        console.log(err)
      }
    },
    async viewclassSave () {
      try {
        if (this.$route.params.cid === 'undefined') {
          this.form.cid = null
        } else {
          this.form.cid = this.$route.params.cid
        }
        const res = await classSave(this.form)
        this.data = res.data
        if (this.$route.params.cid === 'undefined') {
          this.$router.push('/settings/theme/')
        } else {
          this.$router.push('/settings/theme/show/' + this.form.cid)
        }
        return res
      } catch (err) {
        console.log(err)
      }
    },
    async viewselMenu (id, pid, cid) {
      this.loading = true
      try {
        const res = await selMenu({ id: id, pid: pid, cid: cid })
        if (res.data.ruleType === 1) {
          res.data.matchRule = res.data.ruleContent
          res.data.exclusionRule = res.data.exclusionWords
        }
        this.form = res.data
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    goBack () {
      if (this.$route.params.cid === 'undefined') {
        this.$router.push('/settings/theme/')
      } else {
        this.$router.push('/settings/theme/show/' + this.$route.params.cid)
      }
    },
    async refreshMenu () {
      const res = await getNav()
      const [tree,map] = listToTree(res.data)
      console.log(map)
      sessionStorage.setItem('navMap',JSON.stringify(map))
      // this.$store.commit('app/SET_NAV_MAP',map)
    },
    onSubmit () {
      if (this.type !== 'edit') {
        this.form.coreWords = this.form.ruleContent
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            this.form.subjectId = this.$route.params.subjectId
            this.form.parentId = this.$route.params.id
            this.form.cid = this.$route.params.cid
            this.form.coreWords = this.form.ruleContent
            const res = await this.viewclassSave()
            if (res.code === 0) {
              this.$message({
                message: '创建成功',
                type: 'success'
              })
              // this.refreshMenu()
            } else {
              this.$message.error(res.msg)
            }
          } else {
            console.log('error submit!!')
            return false
          }
          this.loading = false
        })
      } else {

        this.form.coreWords = this.form.ruleContent
      // 修改方法Updateclass
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            this.form.id = this.$route.params.id
            this.form.cid = this.$route.params.cid
            this.form.coreWords = this.form.ruleContent
            const res = await this.Updateclass()
            if (res.code === 0) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              // this.refreshMenu()
            } else {
              this.$message.error(res.msg)
            }
          } else {
            console.log('error submit!!')
            return false
          }
          this.loading = false
        })
      }
    },
    async testMyRule (rule) {
      if (!rule) {
        this.$message.warning('规则为空无法进行测试')
        return
      }
      const res = await testRule({ testRule: rule })
      if (res.code === 0) {
        this.ruleText = res.data.join(' | ')
        this.dialogVisible = true
        this.$message.success('恭喜，您配置的规则语法上没有错误')
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .keyword-wrapper {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding-top: 50px;

    .title {
      font-size: 16px;
      color: #000000;
      margin: 0 0 20px 20px;
    }

    .el-form {
      // display: flex;
      // flex-wrap: wrap;
      padding: 0 30px;

      .el-form-item {
        .el-form-item__content {
          textarea {
            width: 100%;
            height: 136px;
            border: 1px solid #DCDFE6;
            color: #606266;
            border-radius: 4px;
          }

          .rule {
            color: #8f8f94;
            font-size: 12px;
            line-height: 22px;
            margin: 0;
            display: block;
          }
          .rules{
            color: #8f8f94;
            font-size: 12px;
            line-height: 22px;
            margin: 0;
          }
        }
      }
    }
  }
</style>
